<template lang="html">
  <div class="RedeemPage">
    <div class="Pending">
      <div class="Pending__Selecteds">
        <Button  color="accent" v-on:click="completeAllRedeems()">{{$t('redeem_complete_selected')}}</Button>
      </div>
      <table class="Pending__Table">
        <tr>
          <th>{{$t('action_select')}}</th>
          <th>{{$t('role_user')}}</th>
          <th>{{$t('redeem_redeem_type')}}</th>
          <th>{{$t('labs_redeemed')}}</th>
          <th>{{$t('table_action_tab')}}</th>
        </tr>
        <tr v-for="r in pendingRedeems.objects">
          <td class="center">
            <div class="Pending__Table--checkbox">
              <input type="checkbox" :id="'checkbox'+r.identifier" v-model="r.selected"/>
              <label :for="'checkbox'+r.identifier"></label>
            </div>
          </td>
          <td>
            <MemberItem
              :key="r.member.identifier"
              :hideTags="true"
              :member="r.member"/>
          </td>
          <td class="center">
            <p v-if="r.redeemType == 'CUSTOM'">{{r.sortName}}</p>
            <p v-if="r.redeemType != 'CUSTOM'">{{$filters.formatRedeemType(r.redeemType)}}</p>
          </td>
          <td class="center">
            <p class="primaryColor">{{r.points}}</p>
          </td>
          <td class="center">
            <Button color="accent" v-on:click="completeRedeems(r)">{{$t('action_complete')}}</Button>
          </td>
        </tr>
      </table>
      <Pagination
      v-if="pendingRedeems.pages > 1"
      v-model="pagination"
      :records="pendingRedeems.count"
      :per-page="pendingRedeems.pageSize"
      @paginate="fetchPendingRedeems"/>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations  } from 'vuex';
import {
  COMMUNITY_FETCH_MEMBERS,
} from '@/store/community/members.module';
import {
  COMMUNITY_UPDATE_REDEEM_INFO,
  COMMUNITY_FETCH_INFO
} from '@/store/community/info.module'
import {
  COMMUNITY_FETCH_PENDING_REDEEMS,
  COMMUNITY_COMPLETE_REDEEM,
  COMMUNITY_ADD_REDEEM
} from '@/store/community/redeem.module'
import {USER_FETCH_PROFILE} from '@/store/user.module'
import store from '@/store';
import Button from '~/components/Button';
import { sameDayDates } from '@/utils';
import Pagination from 'v-pagination-3';
import MemberItem from '@/components/MemberItem.vue';

export default {
  name: 'ActivitiesPage',

  components:{
    Button,
    Pagination,
    MemberItem,
  },

  computed: {
    ...mapGetters([
      'isClient',
      'isModerator',
      'isUser',
      'communityInfo',
      'userProfile',
      'origin',
      'communityMembers'
    ]),
  },

  data() {
    return {
      redeemEnabled: false,
      currentRedeemPoints:{

      },
      redeemed: false,
      hideModal:true,
      pendingRedeems:{},
      redeemArray:[],
      pagination:1,
      redeemEdition:{
        pointsRedeemAwards:"",
        pointsRedeemDate:"",
      },
      isEditingDate:false,
      isEditingAwards:false,
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
    }
  },

  methods: {
    fetchPendingRedeems: async function(page){
      await store.dispatch(COMMUNITY_FETCH_PENDING_REDEEMS,{page:page-1})
      .then((data) => {
        for (let o of data.objects) {
          o.member = this.findMemberById(o.ownerId)
        }
        this.pendingRedeems = data
        $('html,body').animate({scrollTop: $('.Pending').offset().top}, 800);
      })
    },

    findMemberById(id){
      for (let m of this.communityMembers) {
        if (m.identifier == id) return m
      }
    },

    fetchMembers: async function(){
      await store.dispatch(COMMUNITY_FETCH_MEMBERS,{
        order:"recent",
      });
    },

    completeAllRedeems: async function(){
      let data = {
        elPointsTransactionIds:[]
      }
      for (let p of this.pendingRedeems.objects) {
        if (p.selected) data.elPointsTransactionIds.push(p.identifier)
      }
      await store.dispatch(COMMUNITY_COMPLETE_REDEEM,{data:data});
      Swal.fire("",this.$t('redeem_success'),"success")
      this.fetchPendingRedeems(this.pagination);
    },

    completeRedeems: async function(redeem){
      let data = {
        elPointsTransactionIds:[redeem.identifier]
      }
      await store.dispatch(COMMUNITY_COMPLETE_REDEEM,{data:data});
      Swal.fire("",this.$t('redeem_success'),"success")
      this.fetchPendingRedeems(this.pagination);
    },

    openModal(c){
      if (!this.isClient) {
        let type = c.name.toUpperCase()
        if (c.name.toUpperCase() != "AMAZON" && c.name.toUpperCase() != "PAYPAL") type = "RAFFLE"
        this.currentRedeemPoints.type = type
        this.currentRedeemPoints.labs = c.labs
        this.currentRedeemPoints.prize = c.prize
        this.currentRedeemPoints.logo = c.logo
        this.currentRedeemPoints.name = c.name
        this.currentRedeemPoints.description = c.description
        this.redeemed = false
        this.hideModal = false
      }
    },

    closeModal(){
      this.hideModal = true
      this.redeemed = false
    },

    redeemPoints: async function(){
      let data = {
        points: this.currentRedeemPoints.cards * this.currentRedeemPoints.labs,
        redeemType: this.currentRedeemPoints.type
      }
      if (this.currentRedeemPoints.cards == undefined || this.currentRedeemPoints.cards == 0) return
      await store.dispatch(COMMUNITY_ADD_REDEEM,{data:data})
      .then((data) => {
        this.redeemed = true
        this.currentRedeemPoints.cards = 0
        store.dispatch(USER_FETCH_PROFILE)
      })
    },

    isRedeemEdition: function(property){
      if (property == "pointsRedeemAwards") {
        this.isEditingAwards = true
        this.redeemEdition.pointsRedeemAwards = this.communityInfo.pointsRedeemAwards
      }
      else {
        this.isEditingDate = true
        this.redeemEdition.pointsRedeemDate = this.communityInfo.pointsRedeemDate
      }
    },

    disableSubmit: function(text,type){
      if (type == "date") {
        let date = new Date();
        let date2 = new Date(text);
        return text == null || (date2 < date && !sameDayDates(date2,date))
      }
      if (type == 'text') {
        let baseText = text.replace(/<[^>]*>?/g, '')
        return baseText.length < 10
      }
    },

    saveForm: async function(property){
      let data = {}
      if (property == "pointsRedeemAwards") {
        if (this.redeemEdition.pointsRedeemAwards.length < 20) {
          return
        }
        data.pointsRedeemAwards = this.redeemEdition.pointsRedeemAwards
        data.pointsRedeemDate = this.communityInfo.pointsRedeemDate
      }
      else{
        if (this.redeemEdition.pointsRedeemDate == null) {
          return
        }
        data.pointsRedeemAwards = this.communityInfo.pointsRedeemAwards
        data.pointsRedeemDate = this.redeemEdition.pointsRedeemDate
      }

      data.redeemCards = this.communityInfo.redeemCards

      await store.dispatch(COMMUNITY_UPDATE_REDEEM_INFO,{data:data})
      .then((data) => {
        store.dispatch(COMMUNITY_FETCH_INFO)
        Swal.fire("",this.$t('success_correctly_saved'),"success")
      })

      if (property == "pointsRedeemAwards") {
        this.isEditingAwards = false
      }
      else{
        this.isEditingDate = false
      }
    },

    disableRedeem(){
      let date = new Date();
      date.setHours(0,0,0,0);
      if (new Date(this.communityInfo.pointsRedeemDate) <= date) {
        this.redeemEnabled = true
      }
    },
  },
  async mounted(){
    if (this.isClient){
      await this.fetchMembers()
      this.fetchPendingRedeems(1);
    }
    this.disableRedeem()
  },
  updated(){
    this.disableRedeem()
  }
}
</script>
<style scoped lang="scss">
.RedeemPage{
  background: white;
  display:flex;
  justify-content: space-between;
  padding:0 15% 20px 15%;
  @media screen and ( max-width: 768px ) {
    padding: 0!important;
    flex-direction:column;
  }
  @media screen and ( max-width: 980px ) {
    padding: 0 5%;
  }
  &__LeftPane{
    width: 30%;
    display:flex;
    flex-direction:column;
    background: #f4f4f4;
    padding: 40px 20px 140px 20px;
    border-bottom: 4px solid #f8b656;
    @media screen and ( max-width: 768px ) {
      width: 100%;
    }
    &--Title{
      color: #f7ac3b;
      text-transform: uppercase;
      h1{
        font-size: 20px;
        font-weight: 500;
      }
      h2{
        font-size: 43px;
        margin-top: -10px;
      }
    }
    &__Timer{
      &--Title{
        font-size:20px;
        font-weight: bold;
      }
      &--Wrapper{
        display:flex;
      }
      &--Container{
        margin-right: 10px;
      }
      &--Num{
        font-size: 40px;
        padding: 0 10px;
        color: var(--primary-color);
        font-weight: bold;
        background: white;
      }
      &--Text{
        font-size: 10px;
        font-weight: bold;
      }
    }
    &__Info{
      &--Text{
        font-size: 12px;
        color: #8a8a8a;
        margin-top:15px;
      }
    }
  }
  &__RightPane{
    width: 60%;
    padding-top: 40px;
    @media screen and ( max-width: 768px ) {
      width: 100%;
      padding: 40px 20px 0 20px;
    }
    &--Info{
      font-size: 12px;
    }
    &--Cards{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .Card{
        width: calc( (100% / 2) - 20px);
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 0px 5px #f2f2f2;
        padding: 1px;
        background: white;
        &--title{
          width: 100%;
          background: #f7ac3b;
          color: #fff;
          padding: 3px 0;
          font-size: 13px;
          text-align: center;
        }
        &--image{
          width: 100%;
          height: 100px;
          border-bottom: 1px solid #f4f4f4;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 90%;
            height: 90%;
          }
        }
        &--text{
          text-align: center;
          margin: 1px 0;
          padding:10px;
          font-size: 12px;
          color: #8a8a8a;
          width: 100%;
        }
        &--button{
          font-size: 14px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;
          button{
            background: #f7ac3b;
          }
        }
      }
    }
  }
}
.EditionButton{
  display:flex;
  align-items: center;
  position:relative;
  margin-bottom: 10px;
  .tooltip-icon{
    cursor          : pointer;
    position        : relative;
    font-size       : 12px;
    color: black;
    margin: 0 3px;
    &::after{
      content: '';
      position: absolute;
      left: 50%;
      width: 12px;
      height: 12px;
      top: -10px;
      transform: translate(-50%,-50%) rotate(45deg);
      background-color: #EEEEEE;
      border: 1px solid;
      border-color: transparent var(--primary-color) var(--primary-color) transparent ;
      box-shadow: 3px 3px 4px rgba(0,0,0,0.5);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
    }
    &:hover p, &:hover::after{
      visibility: visible;
      opacity: 1;
    }
    .infoText{
      min-width: 200px;
      left: 0;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      top: -10px;
      transform: translate(-50%, -100%);
      padding: 10px 20px;
      color: #444444;
      background-color: #EEEEEE;
      font-size: 10px;
      border-radius: 8px;
      z-index: 1;
      position: absolute;
      border: 1px solid var(--primary-color);
      box-shadow: 0 1px 4px rgba(0,0,0,0.5);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
    }
  }
}
.button-group{
  display: flex;
  button{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.small-button{
  font-size: 11px;
  text-transform: uppercase;
}
.redeemLabsP{
  color: var(--primary-color);
  font-weight: bold;
}
.Modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  &__content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  &__header {
    padding: 6px 16px;
    background-color: #f8b656;
    color: white;
    h2{
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__body {
    padding: 16px;
    font-size: 13px;
    color: #8a8a8a;
    &__content{
      display: flex;
    }
    p{
      margin: 10px 0;
    }
    .imgs{
      padding: 20px;
      img{
        border: 1px solid lightgray;
        width: 150px;
        height: 120px;
        margin-right: 20px;
        padding: 10px;
      }
    }
    .selection{
      display: flex;
      select{
        outline: none;
        border: 1px solid #f8b656;
      }
      button{
        text-transform: uppercase;
        cursor: pointer;
        margin-left: 20px;
        background: #f8b656;
        color:white;
        padding: 5px 10px;
        font-size: 18px;
        border: none;
      }
    }
    .text-center{
      font-size: 15px;
      text-align: justify;
      padding: 10px 0 0 0;
      color: #f8b656;
      font-weight: bold;
    }

  }
  .close {
    color: white;
    float: right;
    font-size: 20px;
    font-weight: bold;
    &:hover{
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  @media screen and ( max-width: 768px ) {
    .Modal__content{
      width: 100%;
    }
  }
  @media screen and ( max-width: 495px ) {
    .Modal__body__content{
      flex-direction: column;
    align-items: center;
    }
  }
}
.Pending{
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items:center;
  padding: 20px 0;
  &__Table{
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    th{
      padding: 10px 0;
    }
    tr{
      border:1px solid lightgray;
    }
    td.center{
      text-align:-webkit-center;
    }
    &--checkbox{
      input{
        display: none;
      }

      label:before{
        content:'';
        background:transparent;
        border: 1px solid gray;
        border-radius: 3px;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        text-align:center;
        vertical-align:middle;
        cursor: pointer;
        font-size: 15px;
        font-family:'Times New Roman';
        color:white;
        transition:0.5s;
      }
      input:checked + label:before{
        content: '\2714';
        color:var(--primary-color);
      }
    }

    p{
      font-weight: bold;
      &.primaryColor{
        color:var(--primary-color);
      }
    }
  }
  &__Selecteds{
    margin:10px 0;
    width:100%;
  }
}
</style>
