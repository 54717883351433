<template>
  <div class="Tag" :class="{'Tag--Clickable' : isClickable, 'Tag--Active' : isActive, 'Tag--Public' : isPublic }">
    <span class="Tag__Name" v-on:click="!isActive && $emit('add')">{{ name }}</span>
    <span class="Tag__RemoveButton" v-on:click="$emit('remove')">&times;</span>
  </div>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    name: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style scoped lang="scss">
.Tag {
  display: inline-flex;
  align-items: center;
  background-color: #eee;
  line-height: 1.5em;
  padding: 0 .6em;
  border-radius: 20px;
  overflow: hidden;
  cursor: default;

  /* &__Name {

  } */

  &__RemoveButton {
    background-color: rgba(0, 0, 0, 0.15);
    display: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &--Clickable {
    cursor: pointer;
  }

  &--Public {
    background-color: #bbb;
    color: #fff;
    padding-right: .6em;
  }

  &--Active {
    background-color: var(--primary-color);
    color: #fff;
    padding-right: 0;

    .Tag__Name {
      padding-right: .2em;
    }

    .Tag__RemoveButton {
      display: block;
      padding: 0 .2em;
    }
  }

  &.TagV2{
    background: white;
    &.Tag--Active{
      color: white;
      background: var(--primary-color);
      .Tag__Name{
        color: white;
      }
    }
    .Tag__Name{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      letter-spacing: 0.03em;
      color: #3E3E3E;
      text-transform: uppercase;
    }
  }

  &.TagV3{
    background: rgb(231, 230, 230);
    &.Tag--Active{
      color: white;
      background: var(--primary-color);
      .Tag__Name{
        color: white;
      }
    }
    .Tag__Name{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      letter-spacing: 0.03em;
      color: #3E3E3E;
      text-transform: uppercase;
    }
  }

}
</style>
