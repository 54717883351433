<template>
  <button
    class="Button"
    :type="buttonType"
    :class="styleClasses"
    :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    text: String,

    color: {
      type: String,
      default: 'basic',
      validate: function (value) {
        let allowedTypes = ['basic', 'primary', 'accent', 'warning'];

        return allowedTypes.includes(value);
      },
    },

    type: {
      type: String,
      default: 'default',
      validate: function (value) {
        let allowedTypes = ['default', 'link'];

        return allowedTypes.includes(value);
      },
    },

    size: {
      type: String,
      default: 'md',
      validate: function (value) {
        let allowedTypes = ['sm', 'md']

        return allowedTypes.includes(value)
      },
    },

    disabled: {
      type: Boolean,
      default: false
    },

    buttonType: {
      type: String,
      default: "button",
    }
  },

  computed: {
    styleClasses() {
      return {
        // Colors
        'Button--Basic': this.color == 'basic',
        'Button--Primary': this.color == 'primary',
        'Button--Accent': this.color == 'accent',
        'Button--Warning': this.color == 'warning',

        // Types
        'Button--Link': this.type == 'link',

        // Sizes
        'Button--Sm': this.size == 'sm',
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.Button {
  padding: .5em 1em;
  color: #FFF;
  font-weight: bold;
  font-size: 1em;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0px 0px 6px #BBB;
  }
}

.Button--Primary {
  background-color: var(--primary-color);
}

.Button--Accent {
  background-color: var(--accent-color);
}

.Button--Warning {
  background-color: var(--warning-color);
}

.Button--Basic {
  color: #333;
}

.Button--Link {
  background-color: transparent;

  .Button--Primary {
    color: var(--primary-color);
  }

  .Button--Accent {
    color: var(--accent-color);
  }

  .Button--Warning {
    color: var(--warning-color);
  }
}

.Button--Sm {
  font-size: 12px;
}
</style>
