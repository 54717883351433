<template lang="html">
  <div class="Avatar" :class="{labs:allowLabs,'avatar-radius':radius}">
    <div class="Avatar__Background" :style="{ backgroundImage: 'url(\'' + src + '\')' }">
      <div class="Avatar--Online" v-if="online"></div>
    </div>
    <div class="Avatar__Points" v-if="allowLabs">
      <p class="Avatar__Points--Num">{{labs}}</p>
      <p class="Avatar__Points--Text">LABs</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',

  props: {
    src: {
      type: String,
      default: 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png',
    },
    labs: {
      type: Number,
      default: 0,
    },
    allowLabs: {
      type: Boolean,
      default: false,
    },
    online: {
      type: Boolean,
      default:false,
    },
    radius:{
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.Avatar {
  width:90px;
  height: 72px;
  box-shadow: 0 0 10px #ddd;
  display: flex;
   -webkit-print-color-adjust: exact;
   &.avatar-radius{
     border-radius: 50%;
     .Avatar__Background{
       border-radius: 50%;
     }
   }
  &.labs{
    width:130px;
    &__Points{
      display:none;
    }
  }
  &__Background{
    width: 100%;
    height: 100%;
     -webkit-print-color-adjust: exact;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    position:relative;
    background-color: var(--primary-color);
  }
  &__Points{
    min-width: 57px;
    color: white;
    margin-left:2px;
    &--Num{
      background: #f8b656;
      font-size: 21px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom:2px;
      height: 55px;
    }
    &--Text{
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      background: #4f4f4f;
    }
  }
  &--Online{
    width:13px;
    height: 13px;
    border-radius: 13px;
    background: #a1de5f;
    position:absolute;
    top:-7px;
    left: -7px;
  }
}
</style>
