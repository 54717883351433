import { createVNode, render }  from 'vue'
import moment                   from 'moment';
import store                    from '@/store';
import { DEBOUNCE_SPEED }       from '#/constants/constants.type';
import {
  USER_GET_NICKNAME_AVAILABILITY,
  USER_FETCH_PROFILE
} from '@/store/user.module.ts';
import vm from '@/main';

export const copyTextToClipboard = (text) => {
  var element = $('<textarea>').val(text).appendTo('body').select();
  document.execCommand('copy');
  $(element).remove();
}

export const extractLangPrefix = (lang) => {
  return lang.toLowerCase().substring(0, 2);
};

export const verifyEmail = (mail) => {
  const regex = new RegExp( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
  return mail !== '' && regex.exec( mail );
}

export const isEmptyText = (text) => {
  if (text == undefined) return 0;
  return text.length == 0;
}

export const sameDayDates = (date1,date2) => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

export const isYouTubeVideo = (url) => {
  if (url != undefined && url != '') {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      let match = url.match(regExp);
      if (match && match[2].length == 11) {
          return true
      }
      else {
          return false
      }
  }
}

/**
 * Delays an action (func) for X millis (delay).
 *
 * @param {Object}   context The current context.
 * @param {Function} func    A callback function.
 * @requires debounced       A context's property to hold the timeout ID.
 */
export const debounce = ( context, func, delay ) => {
  clearTimeout( context.debounced );
  context.debounced = setTimeout( ( ) => func( ), delay )
}

///////////////////////////////////////////////////////
////////////////// Profile Utilities //////////////////
///////////////////////////////////////////////////////

/**
 * Fetches user's profile from the API so the inputs can be filled with it.
 *
 * @param {Object} context  The context (usually 'this') to apply changes to.
 */
export const fetchUserProfile = ( context, withoutNick ) => {
  store.dispatch( USER_FETCH_PROFILE, {communityName: context.$route.params.origin})
  .then( ( profile ) => {
    context.editForm = {
      picture:          profile.picture,
      nickname:         profile.nickname,
      currentNickname:  profile.nickname,
      name:             profile.name,
      surname1:         profile.surname1,
      email:            profile.email,
      gender:           profile.gender ? profile.gender : 'M',
      bornDate:         profile.bornDate,
      province:         profile.province,
      medals:           profile.medals,
      commentsNum:      profile.commentsNum,
      afterLoginComplete: profile.afterLoginComplete
    }
    if (withoutNick) {
        context.editForm.nickname = null;
    }
  } )
  .catch( ( error ) => {
    console.log( 'Oops!' );
    console.log( error );
  } );
}

/**
 * Updates the province object in the user data object (editForm).
 *
 * @param {String} value    The new value for the province.
 * @param {Object} context  The context (usually 'this') to apply changes to.
 */
export const updateProvince = ( value, context ) => {
  context.errorLog = context.errorLog && '';
  context.editForm.province = {
    lovName   : "provinces",
    id        : Number( value ),
    elementId : Number( value )
  };
}

/**
 * Fires a debounced API call to check if the nickname is available.
 *
 * @param {Object} event    The fired event object.
 * @param {Object} context  The context (usually 'this') to apply changes to.
 */
export const isNicknameAvailable = ( event, context ) => {
  const { keyCode, target: { value } } = event;

  if ( keyCode === 13 ) return; // Prevents checking if pressed enter.

  context.errorLog = context.errorLog && '';

  if ( context.reqDelay ) clearTimeout( context.reqDelay );

  context.reqDelay = setTimeout(
    ( ) => checkNicknameOnAPI( value, context ), DEBOUNCE_SPEED
  );
}

/**
 * Check against the API if the nickname is available.
 *
 * @param {String} value    The actual nickname.
 * @param {Object} context  The context (usually 'this') to apply changes to.
 */
const checkNicknameOnAPI = ( value, context ) => {
  context.editForm.nickname = value;

  if ( value == context.editForm.currentNickname ) return;
  if ( value === '' )  return;

  context.nickname.loading  = true;

  store.dispatch( USER_GET_NICKNAME_AVAILABILITY, { nickname: value } )
    .then( ( data ) => {
      context.nickname.loading = false;
      if ( !data || !data.availability || /\s/g.exec( value ) ) {
        context.nickname.error = vm.$t('error_already_user_exists');
        context.errorLog       = context.nickname.error;
      } else {
        context.nickname.error = '';
        context.errorLog       = context.nickname.error;
      }
    } )
    .catch( error => {
      console.log( 'error' );
      console.log( error );
    } );
}

export const mount = (component, { props, children, element, app } = {}) => {
    let el = element

    let vNode = createVNode(component, props, children)
    if (app && app._context) vNode.appContext = app._context
    if (el) render(vNode, el)
    else if (typeof document !== 'undefined' ) render(vNode, el = document.createElement('div'))

    const destroy = () => {
        if (el) render(null, el)
        el = null
        vNode = null
    }

    return { vNode, destroy, el }
}

export default {
  extractLangPrefix,
};
