<template lang="html">
  <div class="MemberItem" :class="{'active':member.identifier == currentUserId}">
    <div class="MemberItem__Avatar">
      <Avatar class="MemberItem__Avatar--pic" :src="member.picture" :labs="member.points" :allowLabs="!hideGamification" :online="member.online"/>
    </div>
    <div class="MemberItem__Info">
      <h3 class="MemberItem__Name">{{ member.nickname }}</h3>
      <div class="MemberItem__Attributes">
        <span>{{ $t('age', { count: member.age }) }} | </span>
        <span v-if="member.gender == GENDER_MALE">{{ $t('gender_M') }} | </span>
        <span v-if="member.gender == GENDER_FEMALE">{{ $t('gender_W') }} | </span>
        <span>{{ member.province && $t(member.province.text) }}</span>
      </div>
      <div class="MemberItem__Tags" v-if="!hideTags">
        <Tag
          v-for="tag in member.tags"
          :name="tag"
          :isClickable="false"
        />
        <Tag
          v-for="tag in member.publicTags"
          :name="tag"
          :isPublic="true"
          :isClickable="false"
        />
      </div>
      <div class="MemberItem__Remind" v-if="allowRemind && !member.hastParticipated" v-on:click="$emit('remind-member', member)">
        <i class="fas fa-paper-plane"></i>{{$t('send_reminder')}}

      </div>
    </div>
    <div class="MemberItem__Actions">
        <img src="@/assets/img/plus.png"
           v-if="allowAdd"
           v-on:click="$emit('add-member', member)">

        <img src="@/assets/img/delete.png"
          v-if="allowDelete"
          v-on:click="$emit('delete-member', member)">

        <i class="fas fa-ellipsis-v memberMenu" v-if="allowDeleteFromCommunity" v-on:click="showOptionsMenu()"></i>
        <div class="memberMenu__container" v-if="showMenu">
          <p v-if="allowDeleteFromCommunity" v-on:click="$emit('delete-member-community', member)">
            <i class="fa fa-trash"></i>{{$t('action_delete')}}
          </p>
        </div>
    </div>
  </div>
</template>

<script>
import {
  GENDER_MALE,
  GENDER_FEMALE,
} from '~/constants/user-genders.type.js';
import Avatar from '~/components/Avatar';
import Tag from '~/components/Tag';
import $ from 'jquery';
export default {
  name: 'MemberItem',

  components: {
    Avatar,
    Tag
  },

  props: {
    member: {
      type: Object,
    },

    currentUserId:{
      type:Number,
      default:-1,
    },

    hideTags: {
      type: Boolean,
      default: false,
    },

    allowAdd: {
      type: Boolean,
      default: false,
    },

    allowDelete: {
      type: Boolean,
      default: false,
    },

    allowRemind: {
      type: Boolean,
      default: false,
    },

    allowDeleteFromCommunity: {
      type: Boolean,
      default: false,
    },

    hideGamification: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      GENDER_MALE: GENDER_MALE,
      GENDER_FEMALE: GENDER_FEMALE,
      showMenu: false,
    };
  },

  methods:{
    hideAllMenus: function (e) {
      var container = $(".MemberItem__Actions");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        this.showMenu = false
      }
    },
    showOptionsMenu: function(){
      $('.memberMenu__container').hide()
      this.showMenu=!this.showMenu
    }
  },
}
</script>

<style lang="scss">
  .CommentInput__UserAvatar, .Comment__UserAvatar, .MemberItem__Avatar--pic, .ChatPage__Avatar--pic {
    width: 100px !important;
    height: 50px !important;
    box-shadow: none !important;

    .Avatar__Background {
      width: 49px;
      height: 50px;
    }

    .Avatar__Points {
      width: 49px !important;
      min-width: unset !important;

      &--Num {
        height: 33px;
        font-size: 1rem;
      }

      &--Text {
        height: 15px;
        font-size: 0.6rem;
      }
    }
  }

  .Comment__Content {
    .Comment__Body {
      color: black !important;
      font-size: .8rem !important;
      margin: 0 !important;
      padding-top: 0.5rem !important;
    }
  }

  .Comment__PublishDate {
    margin: 0 !important;
  }

  .ChatPage__Body__Members__Item .name {
    font-size: .8rem !important;
    font-weight: bold;
  }
</style>

<style scoped lang="scss">
.Tag {
  cursor: default;
  margin-left: 5px;
  margin-top: 5px;
}

.MemberItem {
  padding: 15px;
  background-color: #FFF;
  display: flex;
  position: relative;
  align-items: center;
  flex: 1 1 350px;
  &.active{
    border: 1px solid var(--primary-color)!important;
    z-index: 1;
  }
  &__Avatar {
    margin-right: 1rem;
  }

  &__Info {

  }

  &__Name {
    margin: 0;
    font-weight: bold;
    font-size: .8rem;
  }

  &__Attributes {
    text-transform: capitalize;
    font-size: .8em;
    color: #888;
  }

  &__Tags {
    text-transform: uppercase;
    font-size: 0.8rem !important;
    text-transform: capitalize;
    padding: 5px 0;
  }

  &__Remind {
    color: #f6c07e;
    text-transform: uppercase;
    font-size: 0.7em;
    cursor: pointer;
    i{
      margin-right: 5px;
    }
  }

  &__Actions{
    position: absolute;
    right: 10px;
    top: 30%;
    cursor: pointer;
    .memberMenu{
      color: #888;
      position: relative;
      padding: 0 .5rem;
      &__container{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 30px;
        right: 10px;
        z-index: 1;
        background: white;
        padding: 10px;
        border: 1px solid #F1F1F1;
        p{
          margin: 10px 0;
          border-bottom: 1px solid #F1F1F1;
          color: #4a4949;
          display:flex;
          align-items: center;
          &:last-of-type {
            border-bottom: 1px solid transparent;
            i {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
